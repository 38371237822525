import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { style, animate, animation } from '@angular/animations';

@Component({
  selector: 'app-drawings',
  templateUrl: './drawings.component.html',
  styleUrls: ['./drawings.component.scss'],
})
export class DrawingsComponent implements OnInit {
  fadeIn = animation([
    style({ opacity: 0 }), // start state
    animate('300ms', style({ opacity: 1 })),
  ]);

  fadeOut = animation([animate('300ms', style({ opacity: 0 }))]);

  _year = parseInt(this._route.snapshot.paramMap.get('year'));
  private _projects: Array<[]>;
  private _project$: BehaviorSubject<any>;
  private _currentProjectImages$: BehaviorSubject<[]>;

  constructor(private _route: ActivatedRoute, private http: HttpClient) {
    this._projects = new Array();
    this._project$ = new BehaviorSubject<any>(null);
    this._currentProjectImages$ = new BehaviorSubject<[]>([]);
  }

  ngOnInit(): void {
    this.sliderTimer()
    this._route.paramMap.subscribe((el: any) => {
      this.http.get('../../../assets/data.json').subscribe((data: any) => {
        this._projects = data;
        this._project$.next(
          this._projects.find((pr: any) => pr.year == "drawings")
        );
        this._currentProjectImages$.next(this.project$.value.images);
      });
    });
  }

  get year() {
    return this._year;
  }

  get project$() {
    return this._project$;
  }

  get currentProjectImages$() {
    return this._currentProjectImages$;
  }

  currentSlide = 0;
  slideTimer;

  sliderTimer() {
    this.slideTimer = window.setTimeout(() => {
      //<<<---using ()=> syntax
      this.onNextClick();
      window.clearTimeout(this.slideTimer)
      this.sliderTimer()
    }, 5000);
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this._currentProjectImages$.value.length - 1 : previous;
    // console.log('previous clicked, new current slide is: ', this.currentSlide);
    window.clearTimeout(this.slideTimer)
    this.sliderTimer()
  }
  
  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this._currentProjectImages$.value.length ? 0 : next;
    // console.log('next clicked, new current slide is: ', this.currentSlide);
    window.clearTimeout(this.slideTimer)
    this.sliderTimer()
  }
  
  onSlideClick(i) {
    this.currentSlide = i === this._currentProjectImages$.value.length ? 0 : i;
    window.clearTimeout(this.slideTimer)
    this.sliderTimer()
  }

///// SWIPE /////
private swipeCoord?: [number, number];
private swipeTime?: number;

swipe(e: TouchEvent, when: string): void {
  const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
  const time = new Date().getTime();

  if (when === 'start') {
    this.swipeCoord = coord;
    this.swipeTime = time;
  } else if (when === 'end') {
    const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
    const duration = time - this.swipeTime;

    if (duration < 1000 //
      && Math.abs(direction[0]) > 30 // Long enough
      && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        // Do whatever you want with swipe
        switch (swipe) {
          case 'next':
            this.onNextClick();
            break;
          case 'previous':
            this.onPreviousClick();
            break;
        }
    }
  }
}
  ///// SWIPE /////
}
