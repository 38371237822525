<nz-layout>
  <figure class="image-container">
    <img class="hero-image" src="../../../assets/home page/Atelier.jpeg" alt="Homa Arkani Portrait" />
    <figcaption>Studio, at La Vallée, Brussels, Belgium</figcaption>
  </figure>
  <!-- <section class="container text-center">
    <h5 class="text-danger mt-3">Is it Eurus or Zephyrus?</h5>
    <p class="text-secondary font-weight-bold desc">A duet with Sam Eggermont</p>
    <p>17 < 19 / 03 / 2023 <span class="devider">|</span> Opening 17 / 03 18h <span class="devider">|</span> Open : 18 & 19 / 03 11h - 18h</p>
    <p><span><a class="a-style text-secondary" href="https://www.nucleo.be/blancoproject/index/nl/380">Blanco</a></span> I Coupure Rechts 308,9000 Ghent,Belgium</p>
  </section> -->
</nz-layout>