<div class="mt-4">
  <div class="carousel" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div class="image-container">
    <ng-container *ngFor="let item of currentProjectImages$ | async; let i = index">
        <img *ngIf="i === currentSlide" class="slide" nz-image
        nzSrc="../../../assets/Drawings/{{item.name}}" alt="{{item.name}}">
      <p *ngIf="i === currentSlide" class="font-weight-bold mt-3">{{item.description}}</p>
    </ng-container>
  </div>
    <!-- controls -->
    <button class="carousel-control-prev " (click)="onPreviousClick()" type="button"> < </button>
    <button (click)="onNextClick()" class="carousel-control-next " type="button"> > </button>
    <div class="circles-container">
      <div *ngFor="let item of currentProjectImages$ | async; let i = index" class="circle" [class.active-slide] = "i === currentSlide" (click)="onSlideClick(i)"></div>
    </div>
  </div>
</div>
<div class="container mt-2">
  <h4 *ngIf="project$.value !==null" class="text-center font-weight-bold text-uppercase "> {{project$.value.project }}
  </h4>
  <br>
  <section class="container">
    <p *ngIf="project$.value!= null" class="pb-5 text-align">{{project$.value.description}}</p>
  </section>
</div>