<a routerLink="/" class="owner">HOMA ARKANI</a>
<script>
  $('.navbar-nav>li>a').on('click', function () {
    $('.navbar-collapse').collapse('hide');
  });

</script>
<nav class="navbar navbar-expand-lg navbar-light bg-light  page-top-margin">
  <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-center" [ngClass]="{ 'show': navbarOpen }">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" (click)="closeNavbar()" routerLink="/" routerLinkActive="active-link" [routerLinkActiveOptions]= "{exact: true}">HOME</a>
      </li>
      <li class="nav-item dropdown">
        <a [class.active-link]="router.isActive('/overview',false)" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          PAINTINGS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['overview', 2023]">2023</a>
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['overview', 2021]">2021</a>
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['overview', 2019]">2019</a>
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['overview', 2016]">2016</a>
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['overview', 2013]">2013</a>
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['overview', 2011]">2011</a>
        </div>
      </li>

      <li class="nav-item">
        <a (click)="closeNavbar()" class="nav-link" routerLink="/news" routerLinkActive="active-link">NEWS</a>
      </li>
<!-- 
      <li class="nav-item">
        <a (click)="closeNavbar()" class="nav-link" routerLink="/projects" routerLinkActive="active-link">PROJECTS</a>
      </li> -->

      <li class="nav-item dropdown">
        <a [class.active-link]="router.isActive('/projects',false)" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          PROJECTS
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['projects', 'flying-carpet']">Flying Carpet</a>
          <a (click)="closeNavbar()" routerLinkActive="active-link" class="dropdown-item" [routerLink]="['projects', 'sorry-not-sorry-festival']">Sorry Not Sorry Festival</a>
        </div>
      </li>

      <li class="nav-item">
        <a (click)="closeNavbar()" class="nav-link" routerLink="/drawings" routerLinkActive="active-link">DRAWINGS</a>
      </li>

      <li class="nav-item">
        <a (click)="closeNavbar()" class="nav-link" routerLink="/about-me" routerLinkActive="active-link">ABOUT</a>
      </li>

      <li class="nav-item book">
        <a (click)="closeNavbar()" class="nav-link" routerLink="/contact" routerLinkActive="active-link">
          CONTACT</a>
      </li>
    </ul>
  </div>
</nav>
<div>
  <router-outlet></router-outlet>
</div>
<!-- <p  class="copy-right pb-3 ">© Copyright Homa-Arkani.com</p> -->
