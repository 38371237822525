<!DOCTYPE html>
<html>

<head>
  <title>Mobile First Responsive</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no">

</head>

<body>

  <figure class="center">
    <img nz-image class="about-image" nzSrc="../../../assets/about/Homa Arkani.jpg" alt="" />
    <figcaption class="font-weight-bold">Born in 1983 | Tehran | Iran </figcaption>
  </figure>

  <section class="container">
    <p>
      Born in Iran,In 1983, Homa Arkani has BA in Graphic design from Art & Architecture university in Tehran and MA in Fine arts,painting, from KASK university in Gent,Belgium. Her work is mainly about her socio-cultural concerns. It seeks to transform cultural or political issues into a poetic and visual form. The flows of liquidity come from eastern poetic belief which is in every moment life makes fresh water current in the atmosphere, so that the water becomes clear and clean again and flows in our body, that we may be alive to it again, and this new water and atmosphere give our body another chance, so maybe this time, we can use it for not muddying our identities and create pains, bring greenness and freshness with us, for ourselves and others, forever.<br>
      Homa is mainly inspired by Persian poetry specifically by Rumi and Western painters like Pieter Bruegel and Hieronymus Bosch by their concepts and she is inspired by Van Gogh in her technique.<br>
      In her opinion, the use of a monochromatic palette can produce powerful imagery and provokes deeply personal experiences to explore emotions and spirituality.
    </p>
  </section>

  <div class="experiences">
  <section class="container">
    <h6 class="font-weight-bold">
      EDUCATION
    </h6>
    <p>2006, Bachelor of Arts, Graphic Design, Art & Architecture University, Tehran, Iran</p>
    <p>2017, Master in Fine Arts, Royal Academy of Fine Arts (KASK), Ghent, Belgium</p>
  </section>

  <section class="container">
    <h6 class="font-weight-bold">SOLO EXHIBITION</h6>
    <p>2023 Is it Eurus or Zephyrus? Collaboration with Sam Eggermont, Blanco,Ghent, Belgium    
    </p>
    <p>2021 A Circus on the Canal, Monastry of the Grauwzusters, University of Antwerp, Belgium    
    </p>
    <p>2019 Tangible Thoughts, Santo Artspace, Ghent, Belgium
    </p>
    <p>2016 Where is my Ney?, Etemad gallery, Tehran, Iran
    </p>
    <p>2013 Aesthetics of a Zebra, The Invisible Line gallery, London, England
    </p>
    <p>2011 Share me, Mohsen gallery, Tehran, Iran
    </p>
    <p>2007 Utopia, Seyhoun gallery, Tehran, Iran
    </p>
  </section>

  <section class="container">
    <h6 class="font-weight-bold">GROUP EXHIBITION

    </h6>
    <P>2022 Triennal Dis(connected), UPC, Duffel, Belgium
    </P>
    <P>2022 The circus we are, Le Delta art centre, Namur, Belgium
    </P>
    <P>2022  Sorry Not Sorry street art festival, Gent, Belgium
    </P>
    <P>2021 Pulcinella,CJK art centrum,Belgium
    </P>
    <P>2020-2022 The Clown Spirit, Ronny Van De Velde gallery, Antwerp,Belgian gallery,Namur Belgium
    </P>
    <P>2019 Contemporary art museum of ”MACVAC”, Valencia, Spain
    </P>
    <P>2019 Kunst In Huis project, Antwerp, Belgium
    </P>
    <P>2018 Komask, Royal Academy of Art, Antwerp, Belgium
    </P>
    <P></P>
    <P>2015 Performance, Boom gallery, Tehran, Iran
    </P>
    <P>2015 The World Viewed, Igreg Art Studio, Tehran, Iran
    </P>
    <P>2014 Primio Combat, Museo di Storia Naturale – Museo Civico G.Fattori, Livorno, Italy
    </P>
    <P>2014 Self Portraits, Igreg Art Studio, Tehran, Iran
    </P>
    <P>2013 Unexposed, European Parliament + Tour & Taxis, Brussels, Belgium (also in Athens & Warsaw)
    </P>
    <P>2012 Fogoorie, Shirin gallery, Tehran, Iran
    </P>
    <P>2012 Iranian Artists, Agoman institude, Lyon, France
    </P>
    <P>2011 +me, Elahe gallery, Tehran, Iran
    </P>
    <P>2010 Window, Mohsen gallery, Tehran, Iran
    </P>
    <P>2010 Kitchen, Mohsen gallery, Tehran, Iran
    </P>
    <P>2007 Iranian Artists, Mani gallery, Koln, Germany
    </P>
  </section>

  <section class="container pb-5">
    <h6 class="font-weight-bold">SCHOLARSHIPS, GRANTS AND AWARDS
    </h6>
    <P> Master in Fine Arts, Royal Academy of Fine Arts, Ghent, Belgium
    </P>
    <P> Finalist KOMASK prize 2018
    </P>
    <P> One of the authors of artistic book “Kunst? Kunst!”
    </P>
  </section>
</div>
</body>

</html>
