import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{
  title = 'homa';

  navbarOpen = false;
  dropdown = true;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  closeNavbar() {
    this.navbarOpen = false;
  }
  constructor(private route: ActivatedRoute, public router: Router) {}
}

