<div class="mt-4">
  <div class="carousel" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div class="image-container">
    <ng-container *ngFor="let item of currentProjectImages$ | async; let i = index">
        <img *ngIf="i === currentSlide" class="slide" nz-image
        nzSrc="../../../assets/projects/{{project$.value.project}}/{{item.name}}" alt="{{item.name}}">
      <p *ngIf="i === currentSlide" class="font-weight-bold mt-3">{{item.description}}</p>
    </ng-container>
  </div>
    <!-- controls -->
    <button class="carousel-control-prev " *ngIf="currentProjectImages$.value.length > 1" (click)="onPreviousClick()" type="button"> < </button>
    <button (click)="onNextClick() "*ngIf="currentProjectImages$.value.length > 1" class="carousel-control-next " type="button"> > </button>
    <div *ngIf="currentProjectImages$.value.length > 1" class="circles-container">
      <div *ngFor="let item of currentProjectImages$ | async; let i = index" class="circle" [class.active-slide] = "i === currentSlide" (click)="onSlideClick(i)"></div>
    </div>
  </div>
</div>
<div class="container mt-2">
  <h4 *ngIf="project$.value !==null" class="text-center font-weight-bold text-uppercase "> {{project$.value.project }}
  </h4>
  <br>
  <section class="container">
    <p *ngIf="project$.value!= null" class="pb-5 text-align">{{project$.value.description}}</p>
  </section>
</div>


<!-- <br>
<nz-carousel [nzEffect]="effect" [nzAutoPlaySpeed]="4000" nzAutoPlay>
  <div nz-carousel-content *ngFor="let item of currentProjectImages$ | async">
    <img class="center-fit  " nz-image nzSrc="../../../assets/{{project$.value.project}}//{{item.name}}"
      alt="item.name" />
    <p class="font-weight-bold">{{item.description}}</p>
  </div>
</nz-carousel>
<button class="carousel-control-prev black" (click)="pre()" type="button">
  < </button> <button (click)="next()" class="carousel-control-next black" type="button">>
</button> -->


<!-- <h1 class="text-center font-weight-bold mt-1">FLYING CARPET</h1>

<div class="mt-4">
  <div class="carousel" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div class="image-container">
    <ng-container *ngFor="let index of array ; let i = index">
      <img class="center-fit mt-2 img-thumbnail" *ngIf="i === currentSlide" class="slide" nz-image
        nzSrc="../../../assets/projects/flying carpet/{{index}}" />
    </ng-container>
  </div>

    <button class="carousel-control-prev " (click)="onPreviousClick()" type="button">
      <
    </button>
    <button (click)="onNextClick()" class="carousel-control-next " type="button">
      >
    </button>
    <div class="circles-container">
      <div *ngFor="let item of array; let i = index" class="circle" [class.active-slide] = "i === currentSlide" (click)="onSlideClick(i)"></div>
    </div>
  </div>
</div>

<section class="container mt-5">
  <h3 class="text-center">New in the Pierkespark: </h3>
  <h6 class="text-center">”A flying carpet that flies to all neighborhoods and brings people together”</h6>
  <br>
  <br>
  <p>The Pierkespark is a park full of artworks. Flying carpet is a real flying carpet that you - corona proof - can sit
    on together. The painting, made by Homa Arkani, really goes on a journey, from district to district.</p>

  <p class="pb-5 text-align" style="color: black;">Sofie Van Waeyenberghe, Cedric Matthys 20-03-21, 19:02 Last update:
    20-03-21, 19:43<br><br>
    Meeting in the Pierkespark just got a bit more fun. You will find Homa Arkani ‘s tapestry, a work of art there.
    Alderman of Buurtwerk Astrid De Bruycker (sp.a) came to cut a real ribbon, that was also a while ago. “A flying
    carpet, that still evokes associations with magic, with fleeing and we hope that this work of art may also be a
    short flight. That it can bring people together on Nowruz. This is sometimes called the festival of lights, and now
    that spring is in the country, we feel it extra hard.” The city of Ghent donated 4,500 euros for the artwork.
    <br><br>
    Homa Arkani says: With my work, which consists of a painted carpet, I try to give people the opportunity to continue
    to meet each other during corona times. A place where people can come together safely and corona-proof. Actually, it
    is a signal that, of course at a sufficient distance from each other, you can still enjoy and enjoy others. People
    are therefore also allowed to sit on it effectively. I put a lot of references in the carpet. The mix of colors and
    textures symbolizes the diversity to me we know here in Ghent. Although we have different backgrounds, together we
    form one harmony, one whole. There is also a lot of myself in it. I am Iranian-Belgian and you can see that in the
    carpet. Here and there are details and references to Flemish and Iranian culture. In this way I put a part of myself
    and of my own identity in. Sometimes it even happened unconsciously that I incorporated my own feelings and
    knowledge. The work is called 'The flying carpet 'because it will move to different places in the city. At the
    moment you can find it in the Pierkespark in the Brugse Poort, but then it will move to its next destination. Just
    as the carpet trade once connected the Eastern with the Western world, I connect our neighbourhoods and people with
    each other. I think the carpet as a symbol for the coming together of different worlds is very beautiful. "The
    flying carpet" is an art project by the Ghent-Iranian artist Homa Arkani in collaboration with VOEM vzw. You can
    currently find the work in the Pierkespark in Brugse Poort. <br><br>
    De Bruycker is delighted with the end result. “We see over and over again that culture bridges the gap, not only
    between the neighborhoods, but also between the people in the neighborhood. Especially with this carpet, we hope
    that this connecting effect will achieve its goal.
  </p>
</section> -->
