<nz-layout>
  <h1 class="text-center mt-2 font-weight-bold">NEWS</h1>

  <section class="container text-center mt-5">
    <a class="a-style " href="https://www.hln.be/gent/streetartfestival-moet-gentenaars-moscou-doen-ontdekken-we-kiezen-bewust-voor-deze-locatie~ab125507/">SORRY NOT SORRY FESTIVAL</a>
    <div>
      <a href="https://www.hln.be/gent/streetartfestival-moet-gentenaars-moscou-doen-ontdekken-we-kiezen-bewust-voor-deze-locatie~ab125507/">
        <img [nzDisablePreview]="true"  nz-image width="60%" class="mt-3" height="auto" nzSrc="../../../assets/news/sorry_not_sorry.jpg" alt="" />
      </a>
      <br>
    </div>
    <hr style="width:100%;text-align:left;margin-left:0">

  </section>


  <section class="container text-center mt-5">
    <a class="a-style " href="https://joannadevos.be/exhibition/thecircusweare/">THE CIRCUS WE ARE</a>
    <div>
      <a href="https://joannadevos.be/exhibition/thecircusweare/">
        <img [nzDisablePreview]="true"  nz-image width="60%" class="mt-3" height="auto" nzSrc="../../../assets/news/the_circus_we_are.jpg" alt="" />
      </a>
      <br>
    </div>
    <hr style="width:100%;text-align:left;margin-left:0">

  </section>
  
  <section class="container text-center mt-5">
    <a class="a-style " href="">
      SOLO EXHIBITION</a>
    <div>
      <a>
        <img nz-image width="60%" class="mt-3" height="auto" nzSrc="../../../assets/home page/home-page.jpg" alt="" />
      </a>
      <br>
      <section class="container text-center mb-5">
        <h5 class="text-danger mt-3 font-weight-bold"> A CIRCUS ON THE CANAL
        </h5>
        <p class="text-secondary font-weight-bold">28.10 - 26.11.2021</p>
        <p>UNIVERSITY OF ANTWEP, BELGIUM</p>
        <p>KLOOSTER VAN DE GRAUWZUSTERS</p>
        <p>LANGE SINT-ANNASTRAAT 7,2000 ANTWERP</p>
      </section>
    </div>
    <hr style="width:100%;text-align:left;margin-left:0">

  </section>
  <section class="container text-center mt-5">
    <a class="a-style "
      href="https://www.hln.be/gent/nieuw-in-het-pierkespark-een-vliegend-tapijt-dat-naar-alle-wijken-vliegt-en-mensen-samenbrengt~a483ec2e">FLYING
      CARPET PROJECT</a>
    <br>
    <a
      href="https://www.hln.be/gent/nieuw-in-het-pierkespark-een-vliegend-tapijt-dat-naar-alle-wijken-vliegt-en-mensen-samenbrengt~a483ec2e">
      <img class="mt-3" nz-image [nzDisablePreview]="true" width="60%" height="auto"
        nzSrc="../../../assets/news/flying-carpet.jpg" alt="" />
    </a>
    <hr style="width:100%;text-align:left;margin-left:0">
  </section>

  <section class="container text-center mt-5">
    <a class="a-style " href="https://joannadevos.be/exhibition/the-clown-spirit">
      GROUP EXHIBITION “CLOWN SPIRIT” by JOANNA DE VOS</a>
    <br>
    <a href="https://joannadevos.be/exhibition/the-clown-spirit">
      <img class="mt-3" [nzDisablePreview]="true" nz-image width="60%" height="auto"
        nzSrc="../../../assets/news/clown-spirit.jpg" alt="" />
    </a>
    <hr style="width:100%;text-align:left;margin-left:0">
  </section>


  <section class="container text-center mt-5">
    <a class="a-style " href="https://artbruxelles.wordpress.com/tag/homa-arkani">
      THE EXHIBITION AT THE EUROPEAN PARLIAMENT</a>
    <br>
    <a href="https://artbruxelles.wordpress.com/tag/homa-arkani">
      <img class="mt-3" [nzDisablePreview]="true" nz-image width="60%" height="auto"
        nzSrc="../../../assets/news/artbruxelles.jpg" alt="" />
    </a>
    <hr style="width:100%;text-align:left;margin-left:0">
  </section>

  <section class="container text-center mt-5">
    <a class="a-style "
      href="https://www.mo.be/interview/iraanse-activiste-homa-arkani-ik-wil-erkend-worden-als-een-internationale-artieste">
      IRANIAN ACTIVIST “HOMA ARKANI” AS AN INTERNATIONAL ARTIST</a>
    <br>
    <a
      href="https://www.mo.be/interview/iraanse-activiste-homa-arkani-ik-wil-erkend-worden-als-een-internationale-artieste">
      <img class="mt-3" [nzDisablePreview]="true" nz-image width="60%" height="auto"
        nzSrc="../../../assets/news/mo.jpg" alt="" />
    </a>
    <hr style="width:100%;text-align:left;margin-left:0">
  </section>
  <section class="container text-center mt-5">
    <a class="a-style " href="https://www.nucleo.be/artist/index/nl/368">
      HOMA ARKANI, NUCLEO</a>
    <br>
    <a href="https://www.nucleo.be/artist/index/nl/368">
      <img class="mt-3" [nzDisablePreview]="true" nz-image width="60%" height="auto"
        nzSrc="../../../assets/news/HOMA-ARKANI-NUCLEO.jpg" alt="" />
    </a>
    <hr style="width:100%;text-align:left;margin-left:0">
  </section>

  <section class="container text-center mt-5">
    <a class="a-style" href="https://www.youtube.com/watch?v=FBDxv_qJTrw">
      GROUP EXHIBITION AT MACVAC MUSEUM IN SPAIN</a>
    <br>
    <br>
    <div class="iframe-container">
      <iframe src="https://www.youtube.com/embed/FBDxv_qJTrw" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

    <hr style="width:100%;text-align:left;margin-left:0">
  </section>


  <section class="container text-center mt-5">
    <a class="a-style " href="https://www.poeziecentrum.be/evenement/tangible-thoughts-expo-literair-labo">
      TANGIBLE THOUGHTS: EXPO & LITRETURE</a><br>
    <a href="https://www.poeziecentrum.be/evenement/tangible-thoughts-expo-literair-labo">
      <img class="mt-3" [nzDisablePreview]="true" nz-image width="60%" height="auto"
        nzSrc="../../../assets/news/tangible_thoughts.jpg" alt="" />
    </a>
    <hr style="width:100%;text-align:left;margin-left:0">
  </section>


  <section class="container text-center mt-5">
    <a class="a-style" href="https://www.youtube.com/watch?v=EdMHxis3Gpg&t=3s">
      HOMA ARKANI’s TEHRAN IN ALL ITS SATIRE</a>
    <br> <br>

    <div class="iframe-container">
      <iframe src="https://www.youtube.com/embed/EdMHxis3Gpg" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>

    <hr style="width:100%;text-align:left;margin-left:0">
  </section>

  <section class="container text-center mt-5">
    <a class="a-style"
      href="https://www.tehrantimes.com/news/402982/Paintings-inspired-by-Persian-carpets-on-show-at-Tehran-gallery">
      PAINTINGS INSPIRED BY PERSIAN CARPETS ON SHOW AT TEHRAN GALLERY</a>
    <br>
    <a href="https://www.tehrantimes.com/news/402982/Paintings-inspired-by-Persian-carpets-on-show-at-Tehran-gallery">
      <img class="mt-3" [nzDisablePreview]="true" nz-image width="60%" height="auto"
        nzSrc="../../../assets/news/tehrantimes.jpg" alt="" />
    </a>
    <hr style="width:100%;text-align:left;margin-left:0">
  </section>

  <section class="container text-center mt-5 mb-5">
    <a class="a-style" href="http://www.premiocombat.it/2014/no-63902">
      PREMIO COMBAT 2014 PRIZE</a>
    <br>
    <a href="http://www.premiocombat.it/2014/no-63902">
      <img class="mt-3" [nzDisablePreview]="true" nz-image width="60%" height="auto"
        nzSrc="../../../assets/news/scrambled-egg-l.jpg" alt="" />
    </a>
  </section>
</nz-layout>
